export default class Readline {
    constructor(options) {
        this.targetId = options.id;
        this.displayPer = options.showPercentage;
        this.zindex = options.zIndex ? options.zIndex : '100';
        this.height = options.height ? options.height : '6px';
        this.color = options.color ? options.color : 'blue';
        this.id = `read-line-${Math.random().toString(36).substring(7)}`;
        this.css = `
        #${this.id}{
          position:fixed;
          left:0;
          top:0;
          z-index:${this.zindex};
          height:${this.height};
          background-color: ${this.color};
        }
        #${this.id}-per{
          position:absolute;
          top:5px;
          right:5px;
          color:#222;
          background-color:rgba(255,255,255,0.7);
          padding:1px 3px;
          border-radius:4px;
        }  
        `;

        this.createModal();
        this.addWidthEvent();
        this.addStyle()

        // this.createModal = this.createModal().bind(this);
        // this.addWidthEvent = this.addWidthEvent().bind(this);
        // this.addStyle = this.addStyle().bind(this);
    }

    createModal() {
        const prompt = document.createElement('div');

        prompt.id = this.id;
        if (this.displayPer) {
            const displayper = document.createElement('div');

            displayper.id = `${this.id}-per`;
            prompt.appendChild(displayper);
        }
        document.body.appendChild(prompt);
    }
    scrollHandler(){
        const readEl = document.getElementById(`${this.id}`);
        let per = null;

        if (this.displayPer) {
            per = document.getElementById(`${this.id}-per`);
        }
        let target = null;

        if (this.targetId)
            target = document.getElementById(`${this.targetId}`);
        else
            target = document.body;

        let total = (target.getClientRects()[0].height - window.innerHeight);

        if (total < 0) {
            total = 1;
        }
        if (-target.getClientRects()[0].top / total <= 0) {
            readEl.style.width = '0vw';
            if (this.displayPer) {
                per.innerHTML = '0%';
            }
        } else if (-target.getClientRects()[0].top / total > 1) {
            readEl.style.width = '100vw';
            if (this.displayPer) {
                per.innerHTML = '100%';
            }
        } else {
            readEl.style.width = `${-target.getClientRects()[0].top / total * 100}vw`;
            if (this.displayPer) {
                per.innerHTML = `${parseInt(-target.getClientRects()[0].top / total * 100, 10)}%`;
            }
        }
    }
    addWidthEvent() {
        this.scrollEvent = this.scrollHandler.bind(this)
        document.addEventListener('scroll',this.scrollEvent);
    }
    addStyle() {
        const linkElement = document.createElement('link');

        linkElement.setAttribute('rel', 'stylesheet');
        linkElement.setAttribute('type', 'text/css');
        linkElement.setAttribute('href', 'data:text/css;charset=UTF-8,' + encodeURIComponent(this.css));
        document.head.appendChild(linkElement);
    }
    destroy(){
        document.removeEventListener('scroll',this.scrollEvent);
        document.getElementById(this.id).remove();
    }
}
